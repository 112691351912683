<template>
  <div class="animated fadeIn">
    <TableWrapper
      :ref="model.name"
      :loading="isLoading"
      actions-mode="event"
      :model="model"
      :columns="columns"
      :filter-by-column="false"
      :read-only="readOnly"
      @create="showBottlingsModal"
      @updated="onUpdate"
      @inserted="onInsert"
      @deleted="onDelete"
    ></TableWrapper>

    <WarehouseLookupModal
      ref="warehouse-lookup"
      @select="onBottlingsAdd"
    ></WarehouseLookupModal>
  </div>
</template>

<script>
import { FORM_MODE } from '@/shared/constants'
import TableWrapper from '@/components/DataView/TableWrapper'
import models from '@/models'
import warehouseServices from '@/services/warehouse.service'
import WarehouseLookupModal from '@/views/Warehouse/PickLists/WarehouseLookupModal.vue'

export default {
  name: 'PickListItemBottlingsTable',
  props: {
    pickListId: {
      type: [String, Number],
      default: ''
    },
    parentRow: {
      type: Object,
      default: () => {}
    },
    mode: {
      type: [Number],
      default: FORM_MODE.VIEW
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  components: { TableWrapper, WarehouseLookupModal },
  data: function () {
    return {
      isLoading: false,
      // parentRow: undefined,
      model: models.warehouse.pickListItemBottlings,
      columns: models.warehouse.pickListItemBottlings.entities.map(e => e.name)
    }
  },
  created () {
    this.initialize()
  },
  mounted () {},
  methods: {
    async initialize () {},
    async fetchData () {
      console.log('PickListItemBottlingTable.fetching data')
      this.isLoading = true
      let response = await warehouseServices.fetchPickListBottlings({
        picklist_id: this.pickListId,
        parent_row: this.parentRow
      })

      this.isLoading = false
console.log('response:', response)
      this.$refs[this.model.name].updateDataSet(response)

      this.$emit('loaded', { parentRow: this.parentRow, data: response })
    },
    getDataSet () {
      return this.$refs[this.model.name].getDataSet()
    },
    updateDataSet (data) {
      return this.$refs[this.model.name].updateDataSet(data)
    },
    onInsert () {},
    async onDelete (e) {
      await warehouseServices.deletePicklistBottling(e)

      this.fetchData()
    },
    showBottlingsModal () {
      this.$refs['warehouse-lookup'].show(this.parentRow)
    },
    async onUpdate (dataset, row) {
      let result = await warehouseServices.updatePicklistBottling(row)

      if (result.status !== 200) {
        this.$form.makeToastError(result.message)
      }

      this.fetchData()
    },
    async onBottlingsAdd (e) {
      console.log('onBottlingsAdd:', e)
      this.isLoading = true
      await warehouseServices.createPicklistBottling({
        picklist_id: this.pickListId,
        bottlings: e
      })
      this.isLoading = false

      this.fetchData()
    }
  }
}
</script>

<style scoped></style>
